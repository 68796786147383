import {Permissions} from '@horn1/api';
import {
  AddIcCall,
  Airplay,
  Badge,
  Book,
  DevicesOther,
  Diversity3,
  ExpandLess,
  ExpandMore,
  Folder,
  FolderOpen,
  PhoneForwarded,
  Settings,
  SettingsApplications,
  TableChart,
} from '@mui/icons-material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useMenuTabs} from 'hooks/use-menu-tabs/useMenuTabs';
import {
  CRM_PERMISSIONS,
  DEMO_SETTINGS_PERMISSIONS,
  DISPATCHER_MODULE_TAB_PERMISSIONS,
  EXTERNAL_TOOLS_PERMISSIONS,
  HANDBOOKS_TAB_PERMISSIONS,
  INPUT_CHANELS_PERMISSIONS,
  INTERNAL_TOOLS_PERMISSIONS,
  OTHER_TAB_PERMISSIONS,
  OUTPUT_CHANELS_PERMISSIONS,
  REPORTS_TAB_PERMISSIONS,
  SETTINGS_TAB_PERMISSIONS,
} from 'hooks/use-menu-tabs/useMenuTabs.consts';
import useOptions from 'hooks/useOptions';
import useUser from 'hooks/useUser';
import {useTranslation} from 'locales/useTranslation';
import React, {useMemo, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import path from 'routes/private/path';

import {DRAWER_WIDTH} from '../configs';
import {MenuProps, SubMenu} from '../models';
import {getActiveMenu} from './utils';

const NewMenu: React.FC<MenuProps> = ({open}) => {
  const t = useTranslation();
  const [, , hasPermission] = useUser();
  const [setting] = useOptions();

  const {showTab} = useMenuTabs();

  const classes = useStyles();

  const [openSubMenu, setOpenSubMenu] = useState<SubMenu>({
    application: false,
    reports: false,
    dispatcherModule: false,
    setting: false,
    handbooks: false,
    other: false,
    crm: false,
    project: false,
    inputChanels: false,
    outputChanels: false,
    externalTools: false,
    internalTools: false,
    demoSettings: false,
  });

  const {pathname} = useLocation();

  const activeMenu = useMemo(() => getActiveMenu(pathname), [pathname]);
  const activeClassName = `Mui-selected ${classes.activeMenu}`;

  const handleOpenSubMenu = (value: keyof SubMenu) => {
    setOpenSubMenu(prev => ({...prev, [value]: !prev[value]}));
  };

  return (
    <List component="nav" style={{paddingLeft: 10}}>
      {showTab(CRM_PERMISSIONS) && hasPermission(Permissions.ReadCRM) && (
        <ListItem button onClick={() => handleOpenSubMenu('project')}>
          <ListItemIcon>
            <AccountTreeIcon
              color={activeMenu === 'project' ? 'primary' : 'inherit'}
            />
          </ListItemIcon>
          <ListItemText primary={t.menu.demo.taskForm.project} />
          {openSubMenu.project ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      {open && (
        <Collapse in={openSubMenu.project}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadCanban) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.canban}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.CRMcanban}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}

      {open && (
        <Collapse in={openSubMenu.project}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadList) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.tasksReport}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.tasksReport.title}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {showTab(CRM_PERMISSIONS) && hasPermission(Permissions.ReadCRM) && (
        <ListItem button onClick={() => handleOpenSubMenu('crm')}>
          <ListItemIcon>
            <Diversity3 color={activeMenu === 'crm' ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText primary={t.menu.demo.CRM} />
          {openSubMenu.crm ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}

      {open && (
        <Collapse in={openSubMenu.crm}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadList) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.crmClients}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.clients}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {open && (
        <Collapse in={openSubMenu.crm}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadList) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.crmAgreements}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.agreementsTitle}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {open && (
        <Collapse in={openSubMenu.crm}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadList) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.financesReport}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.financesReport}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      <ListItem button onClick={() => handleOpenSubMenu('application')}>
        <ListItemIcon>
          <Airplay
            color={activeMenu === 'applications' ? 'primary' : 'inherit'}
          />
        </ListItemIcon>
        <ListItemText
          primary={t.menu.application}
          sx={{opacity: open ? 1 : 0}}
        />
        {openSubMenu.application ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      {open && (
        <Collapse in={openSubMenu.application}>
          <List component="div" disablePadding className={classes.subMenu}>
            <ListItem
              button
              component={NavLink}
              activeClassName={activeClassName}
              to={path.callsDashboard}
            >
              <ListItemText
                className={classes.listItemText}
                primary={t.menu.applicationProcessing}
                sx={{opacity: open ? 1 : 0}}
              />
            </ListItem>
          </List>
        </Collapse>
      )}

      {showTab(REPORTS_TAB_PERMISSIONS) && (
        <ListItem button onClick={() => handleOpenSubMenu('reports')}>
          <ListItemIcon>
            <TableChart
              color={activeMenu === 'reports' ? 'primary' : 'inherit'}
            />
          </ListItemIcon>
          <ListItemText primary={t.menu.reports.title} />
          {openSubMenu.reports ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}

      {open && (
        <Collapse in={openSubMenu.reports}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ViewHistoryReport) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.reportsHistory}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.reports.history}
                />
              </ListItem>
            )}
            {hasPermission(Permissions.ViewHistoryAutoReport) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.reportsHistoryAuto}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.reports.historyAuto}
                />
              </ListItem>
            )}
            {hasPermission(Permissions.ReadAppeals) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.reportsAppeals}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.reports.appeals}
                />
              </ListItem>
            )}
            {hasPermission(Permissions.WatchMailAppeal) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.mails}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.reports.mails}
                />
              </ListItem>
            )}
            {hasPermission(Permissions.RatingReport) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.reportsRating}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.reports.rating}
                />
              </ListItem>
            )}
            {hasPermission(Permissions.ReadNercSlaReport) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.reportsNersSla}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.reports.nersSla}
                />
              </ListItem>
            )}
            {hasPermission(Permissions.ReadConsumerEvaluationReports) &&
              !setting?.controlMode && (
                <ListItem
                  button
                  component={NavLink}
                  to={path.reportsAdvancedSla}
                  activeClassName={activeClassName}
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary={t.menu.reports.advancedSla}
                  />
                </ListItem>
              )}
            {hasPermission(Permissions.ReadQualitySupplyServices) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.reportsQualityOfSupply}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.reports.qualitySupply.title}
                />
              </ListItem>
            )}
            {hasPermission(Permissions.ReadSlaReports) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.reportsSla}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.reports.sla}
                />
              </ListItem>
            )}
            {hasPermission(Permissions.ReadNercTopicsReport) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.reportsNersTopic}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.reports.nersTopics}
                />
              </ListItem>
            )}
            {hasPermission(Permissions.ReadExtendedReports) &&
              !setting?.controlMode && (
                <ListItem
                  button
                  component={NavLink}
                  to={path.reportsAdvancedTopic}
                  activeClassName={activeClassName}
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary={t.reports.advanced.topicTitle}
                  />
                </ListItem>
              )}
            {hasPermission(Permissions.ReadOperatorWorkReports) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.reportsOperatorWorks}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.reports.operatorWork}
                />
              </ListItem>
            )}
            {hasPermission(Permissions.ReadToeOperatorReports) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.reportsOperatorsToe}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.reports.operatorReportsToe}
                />
              </ListItem>
            )}
            {hasPermission(Permissions.AstorReport) && !setting?.controlMode && (
              <ListItem
                button
                component={NavLink}
                to={path.reportAstorWorkService}
                activeClassName={activeClassName}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.reports.astorPage}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}

      {showTab(DISPATCHER_MODULE_TAB_PERMISSIONS) && (
        <ListItem button onClick={() => handleOpenSubMenu('dispatcherModule')}>
          <ListItemIcon>
            <Badge
              color={activeMenu === 'dispatcherModule' ? 'primary' : 'inherit'}
            />
          </ListItemIcon>
          <ListItemText primary={t.menu.dispatcherModule.title} />
          {openSubMenu.dispatcherModule ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}

      {open && (
        <Collapse in={openSubMenu.dispatcherModule}>
          {hasPermission(Permissions.ReadDispatcherModuleTable) && (
            <List component="div" disablePadding className={classes.subMenu}>
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.dispatcherModuleTable}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.dispatcherModule.table}
                />
              </ListItem>
            </List>
          )}
        </Collapse>
      )}

      {showTab(HANDBOOKS_TAB_PERMISSIONS) && (
        <ListItem button onClick={() => handleOpenSubMenu('handbooks')}>
          <ListItemIcon>
            <Book color={activeMenu === 'handbooks' ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText primary={t.menu.handbooks.title} />
          {openSubMenu.handbooks ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}

      {open && (
        <Collapse in={openSubMenu.handbooks}>
          {hasPermission(Permissions.ReadExternalPhoneNumbers) && (
            <List component="div" disablePadding className={classes.subMenu}>
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.handbookExternalPhoneNumbers}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.handbooks.externalPhoneNumbers}
                />
              </ListItem>
            </List>
          )}
          {hasPermission(Permissions.ReadClients) && (
            <List component="div" disablePadding className={classes.subMenu}>
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.handbooksClients}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.clients}
                />
              </ListItem>
            </List>
          )}
        </Collapse>
      )}

      {showTab(SETTINGS_TAB_PERMISSIONS) && (
        <ListItem button onClick={() => handleOpenSubMenu('setting')}>
          <ListItemIcon>
            <SettingsApplications
              color={activeMenu === 'setting' ? 'primary' : 'inherit'}
            />
          </ListItemIcon>
          <ListItemText primary={t.menu.setting.title} />
          {openSubMenu.setting ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}

      {open && (
        <Collapse in={openSubMenu.setting}>
          <List className={classes.subMenu} component="div" disablePadding>
            {hasPermission(Permissions.ReadGeneralSetting) && (
              <ListItem
                activeClassName={activeClassName}
                button
                component={NavLink}
                to={path.settings}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.setting.general}
                />
              </ListItem>
            )}

            {hasPermission(Permissions.ReadTextToSpeech) && (
              <ListItem
                activeClassName={activeClassName}
                button
                component={NavLink}
                to={path.textToSpeech}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.setting.textToSpeechSite.textToSpeech}
                />
              </ListItem>
            )}
            {hasPermission(Permissions.ReadUploadDB) && (
              <ListItem
                activeClassName={activeClassName}
                button
                component={NavLink}
                to={path.uploadDB}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.setting.uploadDB}
                />
              </ListItem>
            )}

            {hasPermission(Permissions.ReadUsers) && (
              <ListItem
                activeClassName={activeClassName}
                button
                component={NavLink}
                to={path.users}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.setting.users}
                />
              </ListItem>
            )}

            {hasPermission(Permissions.ReadRoles) && (
              <ListItem
                activeClassName={activeClassName}
                button
                component={NavLink}
                to={path.roles}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.setting.roles}
                />
              </ListItem>
            )}

            {hasPermission(Permissions.ReadSounds) && (
              <ListItem
                activeClassName={activeClassName}
                button
                component={NavLink}
                to={path.sounds}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.setting.sounds}
                />
              </ListItem>
            )}

            {hasPermission(Permissions.ReadTopics) && (
              <ListItem
                activeClassName={activeClassName}
                button
                component={NavLink}
                to={path.topics}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.setting.topic}
                />
              </ListItem>
            )}

            {hasPermission(Permissions.ReadRoutes) && (
              <ListItem
                activeClassName={activeClassName}
                button
                component={NavLink}
                to={path.routes}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.setting.routes}
                />
              </ListItem>
            )}

            {hasPermission(Permissions.ReadRules) && (
              <ListItem
                activeClassName={activeClassName}
                button
                component={NavLink}
                to={path.rules}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.setting.rules}
                />
              </ListItem>
            )}

            {hasPermission(Permissions.ReadSchedule) && (
              <ListItem
                activeClassName={activeClassName}
                button
                component={NavLink}
                to={path.workingTime}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.setting.workingTime}
                />
              </ListItem>
            )}

            {hasPermission(Permissions.ReadTrunks) && (
              <ListItem
                activeClassName={activeClassName}
                button
                component={NavLink}
                to={path.sipTrunks}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.setting.sipTrunks}
                />
              </ListItem>
            )}

            {hasPermission(Permissions.ReadBlacklist) && (
              <ListItem
                activeClassName={activeClassName}
                button
                component={NavLink}
                to={path.blacklist}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.setting.blacklist}
                />
              </ListItem>
            )}

            {hasPermission(Permissions.ReadMailSenderConfigs) && (
              <ListItem
                activeClassName={`Mui-selected ${classes.activeMenu}`}
                button
                component={NavLink}
                to={path.mailSender}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.setting.mailSenderConfig}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}

      {showTab(OTHER_TAB_PERMISSIONS) && (
        <ListItem button onClick={() => handleOpenSubMenu('other')}>
          <ListItemIcon>
            <DevicesOther
              color={activeMenu === 'other' ? 'primary' : 'inherit'}
            />
          </ListItemIcon>
          <ListItemText primary={t.menu.other} />
          {openSubMenu.other ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}

      {open && (
        <Collapse in={openSubMenu.other}>
          <List component="div" disablePadding className={classes.subMenu}>
            <ListItem
              button
              component={NavLink}
              activeClassName={activeClassName}
              to={path.testing}
            >
              <ListItemText
                className={classes.listItemText}
                primary={t.menu.testing}
              />
            </ListItem>
          </List>
        </Collapse>
      )}

      {open && (
        <Collapse in={openSubMenu.other}>
          <List component="div" disablePadding className={classes.subMenu}>
            <ListItem
              button
              component={NavLink}
              activeClassName={activeClassName}
              to={path.module}
            >
              <ListItemText
                className={classes.listItemText}
                primary={t.menu.module}
              />
            </ListItem>
          </List>
        </Collapse>
      )}

      {showTab(INPUT_CHANELS_PERMISSIONS) &&
        hasPermission(Permissions.ReadInputChanels) && (
          <ListItem
            button
            className={classes.listLongItem}
            onClick={() => handleOpenSubMenu('inputChanels')}
          >
            <ListItemIcon>
              <AddIcCall
                color={activeMenu === 'input-chanels' ? 'primary' : 'inherit'}
              />
            </ListItemIcon>
            <ListItemText primary={t.menu.demo.inputChanels} />
            {openSubMenu.inputChanels ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        )}
      {open && (
        <Collapse in={openSubMenu.inputChanels}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadInputChanelsMessengers) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.chanelsChats}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.inputChanelsMessengers}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {open && (
        <Collapse in={openSubMenu.inputChanels}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadInputChanelsVideoMessengers) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.videoChats}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.inputChanelsVideoMessengers}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {open && (
        <Collapse in={openSubMenu.inputChanels}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadInputChanelsSocialNetworcs) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.socialNetworks}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.inputChanelsSocialNetworcs}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}

      {open && (
        <Collapse in={openSubMenu.inputChanels}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadInputChanelsCRM) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.inputChanelsCRM}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.inputChanelsCRM}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {open && (
        <Collapse in={openSubMenu.inputChanels}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadInputChanelsMarketplace) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.inputChanelsMarketplace}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.inputChanelsMarketplace}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {open && (
        <Collapse in={openSubMenu.inputChanels}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadInputChanelsWebChats) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.inputChanelsWebChats}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.inputChanelsWebChats}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {open && (
        <Collapse in={openSubMenu.inputChanels}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadInputChanelsEmail) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.inputChanelsEmail}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.inputChanelsEmail}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {open && (
        <Collapse in={openSubMenu.inputChanels}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadInputChanelsForms) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.inputChanelsForms}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.inputChanelsForms}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {open && (
        <Collapse in={openSubMenu.inputChanels}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadInputChanelsCRMSiteBilder) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.inputChanelsCRMSiteBilder}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.inputChanelsCRMSiteBilder}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}

      {showTab(OUTPUT_CHANELS_PERMISSIONS) &&
        hasPermission(Permissions.ReadOutputChanels) && (
          <ListItem
            button
            className={classes.listLongItem}
            onClick={() => handleOpenSubMenu('outputChanels')}
          >
            <ListItemIcon>
              <PhoneForwarded
                color={activeMenu === 'output-chanels' ? 'primary' : 'inherit'}
              />
            </ListItemIcon>
            <ListItemText primary={t.menu.demo.outputChanels} />
            {openSubMenu.outputChanels ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        )}
      {open && (
        <Collapse in={openSubMenu.outputChanels}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadOutputChanelsSms) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.outputChanelsSms}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.outputChanelsSms}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {open && (
        <Collapse in={openSubMenu.outputChanels}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadOutputChanelsMessengers) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.outputChanelsMessengers}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.outputChanelsMessengers}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {open && (
        <Collapse in={openSubMenu.outputChanels}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadOutputChanelsCallback) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.outputChanelsCallback}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.outputChanelsCallback}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {open && (
        <Collapse in={openSubMenu.outputChanels}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadOutputChanelsEmail) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.outputChanelsEmail}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.outputChanelsEmail}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}

      {showTab(EXTERNAL_TOOLS_PERMISSIONS) &&
        hasPermission(Permissions.ReadExternalTools) && (
          <ListItem
            button
            className={classes.listLongItem}
            onClick={() => handleOpenSubMenu('externalTools')}
          >
            <ListItemIcon>
              <FolderOpen
                color={activeMenu === 'external-tools' ? 'primary' : 'inherit'}
              />
            </ListItemIcon>
            <ListItemText primary={t.menu.demo.externalTools} />
            {openSubMenu.externalTools ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        )}
      {open && (
        <Collapse in={openSubMenu.externalTools}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadExternalToolsDB) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.externalToolsDB}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.externalToolsDB}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {open && (
        <Collapse in={openSubMenu.externalTools}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadExternalToolsNPSAgents) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.externalToolsNPSAgents}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.externalToolsNPSAgents}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {open && (
        <Collapse in={openSubMenu.externalTools}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadExternalToolsVirtualAgents) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.externalToolsVirtualAgents}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.externalToolsVirtualAgents}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {open && (
        <Collapse in={openSubMenu.externalTools}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadExternalToolsSuperAgents) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.externalToolsSuperAgents}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.externalToolsSuperAgents}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {open && (
        <Collapse in={openSubMenu.externalTools}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadExternalToolsAdveristingOffices) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.externalToolsAdveristingOffices}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.externalToolsAdveristingOffices}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {open && (
        <Collapse in={openSubMenu.externalTools}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadExternalToolsAI) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.externalToolsAI}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.externalToolsAI}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}

      {showTab(INTERNAL_TOOLS_PERMISSIONS) &&
        hasPermission(Permissions.ReadInternalTools) && (
          <ListItem
            button
            className={classes.listLongItem}
            onClick={() => handleOpenSubMenu('internalTools')}
          >
            <ListItemIcon>
              <Folder
                color={activeMenu === 'internal-tools' ? 'primary' : 'inherit'}
              />
            </ListItemIcon>
            <ListItemText primary={t.menu.demo.internalTools} />
            {openSubMenu.internalTools ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        )}
      {open && (
        <Collapse in={openSubMenu.internalTools}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadInternalToolsTimeTracking) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.internalToolsTimeTracking}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.internalToolsTimeTracking}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {open && (
        <Collapse in={openSubMenu.internalTools}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadInternalToolsDataBase) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.internalToolsDataBase}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.internalToolsDataBase}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
      {showTab(DEMO_SETTINGS_PERMISSIONS) &&
        hasPermission(Permissions.ReadDemoSettings) && (
          <ListItem
            button
            className={classes.listLongItem}
            onClick={() => handleOpenSubMenu('demoSettings')}
          >
            <ListItemIcon>
              <Settings
                color={activeMenu === 'demo-settings' ? 'primary' : 'inherit'}
              />
            </ListItemIcon>
            <ListItemText primary={t.menu.demo.settings} />
            {openSubMenu.other ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        )}
      {open && (
        <Collapse in={openSubMenu.demoSettings}>
          <List component="div" disablePadding className={classes.subMenu}>
            {hasPermission(Permissions.ReadsettingsInterface) && (
              <ListItem
                button
                component={NavLink}
                activeClassName={activeClassName}
                to={path.settingsInterface}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={t.menu.demo.settingsInterface}
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      )}
    </List>
  );
};

export default NewMenu;

const useStyles = makeStyles(theme => ({
  subMenu: {
    paddingLeft: theme.spacing(3),

    '& .MuiTypography-body1 ': {
      fontSize: '0.9rem',
    },
  },

  activeMenu: {
    '& > div > span:before': {
      backgroundColor: theme.palette.primary.main,
      width: 7,
      height: 7,
    },
  },

  listItemText: {
    position: 'relative',
    paddingLeft: theme.spacing(3),
    width: `calc(${DRAWER_WIDTH}px - ${theme.spacing(7)})`,
    minWidth: `calc(${DRAWER_WIDTH}px - ${theme.spacing(7)})`,
    transition: 'width 400ms easy-in-out, height 400ms easy-in-out',

    '& > span': {
      '&:before': {
        content: "''",
        position: 'absolute',
        left: '-10px',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 5,
        height: 5,
        borderRadius: '50%',
        backgroundColor: '#757575',
      },
    },
  },
  listLongItem: {
    whiteSpace: 'nowrap',
  },
}));
